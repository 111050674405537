// App.js
import React, { Suspense, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from "react-i18next";
import routes from "./routes";
import { Box, CircularProgress } from "@mui/material";
import { SocketProvider } from "./context/SocketContext";

import "./App.css";
import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  const { i18n } = useTranslation();
  const routing = useRoutes(routes);

  const currentLanguage = i18n.language;
  const direction = currentLanguage === "ar" ? "rtl" : "ltr";

  useEffect(() => {
    if (typeof Notification !== "undefined") {
      // Request notification permission if not granted
      if (Notification.permission === "default") {
        Notification.requestPermission();
      }
    }
  }, []);

  // Set document direction
  document.body.dir = direction;

  // Create MUI theme with direction
  const theme = createTheme({
    direction: direction,
    palette: {
      primary: {
        main: "#ea6852", // Default primary color
      },
      secondary: {
        main: "#606161", // Default primary color
      },

      background: {
        primaryOpacity: "#ea685230", // Primary color with opacity
        main: "#F3F3F1",
        white: "#FFFFFF",
        form: "#F8F8F7",
      },
    },
    typography: {
      fontFamily: "Poppins, sans-serif",
      customStyle: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.02em",
        color: "#000000", // Default color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SocketProvider>
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress />
            </Box>
          }
        >
          <ScrollToTop />
          {routing}
        </Suspense>
      </SocketProvider>
    </ThemeProvider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  Container,
} from "@mui/material";
import { Menu as MenuIcon, Search as SearchIcon } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useConfig from "../../hooks/useConfig";
import { useLocation, useNavigate } from "react-router-dom";
import NestedCategoriesMenu from "./NestedCategoriesDialogMenu";

// Styled search bar
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#FFFFFF",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    color: "black", // Set input text color explicitly
    padding: theme.spacing(0, 0, 0, 0),
    paddingLeft:
      theme.direction === "rtl" ? theme.spacing(0) : theme.spacing(1),
    paddingRight:
      theme.direction === "rtl" ? theme.spacing(1) : theme.spacing(0),
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const search = searchParams.get("search") || "";
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t } = useTranslation(); // The 't' function is used to translate keys
  const { fetchConfig } = useConfig();
  const [searchValue,setSearchValue] = useState(search)


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log('location',location)
      const trimmedValue = e.target.value.trim();
      if (trimmedValue) {
        if(!location.pathname.startsWith('/list')){
          navigate(`/list?search=${encodeURIComponent(trimmedValue)}`);
        }else{
          navigate(`${location.pathname}?search=${encodeURIComponent(trimmedValue)}`);
        }
      } else {
        navigate(`/list`);
      }
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchValue(value.trim())
    if (value.trim() === "" && location?.pathname === "/list") {
      navigate(`/list`);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCategoryClick = (id) => {
    navigate(`/list/${id}`);
    handleClose();
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const navItems = [
    {
      label: t("HOME"),
      path: "/",
    },
    {
      label: t("POST_ADS"),
      path: "/create",
    },
  ];

  // Drawer for mobile navigation
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 0 }}>
        {t("MENU")}
      </Typography>
      <List>
        {navItems.map((item) => (
          <ListItem
            component="a"
            href={item.path}
            sx={{ textAlign: theme.direction === "ltr" ? "center" : "start" }}
            key={item}
          >
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
        <ListItem
          component={Button}
          onClick={handleClick}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          color="white"
          sx={{
            textAlign: theme.direction === "ltr" ? "center" : "start",
            textTransform: "inherit",
          }}
        >
          <ListItemText primary={t("CATEGORIES")} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        color="primary"
        sx={{
          backgroundColor: theme.palette.primary.main,
          padding: "0px",
          height: "40px",
          minHeight: "40px",
          maxHeight: "40px",
        }}
      >
        <Toolbar
          sx={{
            padding: "0px",
            height: "40px",
            minHeight: "40px",
            maxHeight: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          variant="dense"
        >
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
            }}
          >
            {/* Hamburger menu icon for mobile */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { xs: "flex", sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            {/* Desktop Nav Links */}
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                gap: 7,
              }}
            >
              {navItems.map((item, index) => (
                <Button key={index} variant="text">
                  <Typography
                    component="a"
                    href={item.path}
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "100px",
                      textDecoration: "none",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Button>
              ))}
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: "white" }}
              >
                {t("CATEGORIES")}
              </Button>
              <NestedCategoriesMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                setMobileOpen={setMobileOpen}
              />
            </Box>

            <NestedCategoriesMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            setMobileOpen={setMobileOpen}
          />

            {/* Search Bar */}
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                gap: 1,
                backgroundColor: "#FFFFFF",
                padding: "0 8px",
                borderRadius: "1px",
                maxWidth: "400px",
                flexGrow: 1,
                minHeight: "30px"
              }}
            >
              <SearchIcon color="action"/>
              <input
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={searchValue}
                placeholder={t("SEARCH")}
                style={{
                  border: "none",
                  outline: "none",
                  fontSize: "16px",
                  flexGrow: 1,
                }}
              />
            </Box>
            {/* Mobile Search Bar */}
            <Search
              sx={{
                display: { xs: "flex", sm: "none" },
                alignItems: "center",
                // gap: 1,
                backgroundColor: "#FFFFFF",
                padding: "0 8px",
                borderRadius: "4px",
                minHeight: "30px"
              }}
            >
              <SearchIcon color="action" />
              <StyledInputBase
                placeholder={t("SEARCH")}
                inputProps={{ "aria-label": "search" }}
                sx={{ width: "100%" }}
              />
            </Search>
          </Container>
        </Toolbar>
      </AppBar>
     
      {/* Mobile Drawer */}
      <Drawer
        open={mobileOpen}
        anchor={theme.direction === "ltr" ? "left" : "right"}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            minWidth: 240,
            minHeight: "100vh",
            overflow:'auto'
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Navbar;

import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./themeSlice";
import authReducer from "./authSlice";
import configReducer from "./configSlice";
import categoryReducer from "./categorySlice";
import createPostReducer from "./createPostSlice";
import adReducer from "./adSlice";
import userReducer from "./userSlice";
import settingsReducer from "./settingsSlice";
import paymentsReducer from "./paymentsSlice";
import adListReducer from "./adListSlice";

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    config: configReducer,
    category: categoryReducer,
    createPost: createPostReducer,
    ad: adReducer,
    user: userReducer,
    Settings: settingsReducer,
    payments: paymentsReducer,
    adList: adListReducer,
  },
});

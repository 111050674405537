import axios from "axios";
import { getAccessToken, getRefreshToken, setTokens } from "./auth";

// Create an Axios instance
const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false; // Indicates if the token is currently being refreshed
let failedQueue = []; // Queue to store requests waiting for a new token

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

// Request interceptor to attach the access token
api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh
api.interceptors.response.use(
  (response) => response, // Return the response for successful requests
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 errors
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried

      if (isRefreshing) {
        // Wait for the refresh to complete
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch((err) => {
            console.error("Request retry failed:", err);
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
          throw new Error("No refresh token available");
        }

        const response = await api.post("/auth/refresh-token", { refreshToken });
        const { accessToken, refreshToken: newRefreshToken } = response.data.data;

        if (accessToken) {
          setTokens(accessToken, newRefreshToken);

          // Update Axios default headers
          api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

          // Retry the original request with the new token
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

          processQueue(null, accessToken); // Resolve queued requests
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        processQueue(refreshError, null); // Reject queued requests
        localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);



export default api;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Apply scroll-to-top only for specific routes
    if (pathname.startsWith("/ad/") || pathname.startsWith("/user/") || pathname.startsWith('/terms-and-conditions')
        || pathname.startsWith('/privacy-policy')
    ) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;

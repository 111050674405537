import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authService } from '../services/auth.api';
import { loginSuccess, logout, setLoginDetiles } from '../store/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const useAuth = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { accessToken, user } = useSelector((state) => state.auth);

    const signUp = async (data) => {
        setLoading(true);
        try {
            dispatch(setLoginDetiles(data));
            const response = await authService.signUp(data);
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const verifyOTP = async (data) => {
        setLoading(true);
        try {
            const response = await authService.verifyOTP(data);
            if (response.success) {
                localStorage.setItem('accessToken', response.data.accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                dispatch(
                    loginSuccess({
                        user: response.data.user,
                        accessToken: response.data.accessToken,
                        refreshToken:response.data.refreshToken
                    })
                );
            }
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const resendOTP = async (data) => {
        setLoading(true);
        try {
            const response = await authService.resendOTP(data);
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const OTPGenerate = async (data) => {
        setLoading(true);
        try {
            const response = await authService.OTPGenerate(data);
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const login = async (data) => {
        setLoading(true);
        try {
            dispatch(setLoginDetiles(data));
            const response = await authService.login(data);
            if (response.success) {
                localStorage.setItem('accessToken', response.data.accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                dispatch(
                    loginSuccess({
                        user: response.data.user,
                        accessToken: response.data.accessToken,
                        refreshToken:response.data.refreshToken
                    })
                );
            }
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const resetPassword = async (payload) => {
        setLoading(true);
        try {
            const response = await authService.resetPassword(payload);
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const forgotPassword = async (payload) => {
        setLoading(true);
        try {
            const response = await authService.forgotPassword(payload);
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const logoutUser = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        dispatch(logout());
    };

    const isAuthenticated = !!accessToken; // Check if accessToken exists


    const executeIfLoggedIn = (callback, ...args) => {
    
        if (isAuthenticated) {
            callback(...args); // Pass arguments to the callback
        } else {
            console.warn('User is not logged in. Redirecting to login page.');
            navigate('/login', { state: { from: location.pathname } });
        }
    };

    return {
        signUp,
        verifyOTP,
        resendOTP,
        login,
        resetPassword,
        forgotPassword,
        logout: logoutUser,
        executeIfLoggedIn,
        accessToken,
        loading,
        error,
        isAuthenticated, // Boolean flag if the user is authenticated
        OTPGenerate,
        user, // Access user data
    };
};

export default useAuth;

import { createSlice } from "@reduxjs/toolkit";

const adListSlice = createSlice({
  name: "adList",
  initialState: {
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    totalItems: 0,
    searchQuery:"",
    sortOption: "",
    filter:{},
    categoryId:"",
  },
  reducers: {
    setAdListData: (state, action) => {
        for (const key in action.payload) {
            state[key] = action.payload[key];
          }
    },
    setFilterData: (state, action) => {
        state.filter= action.payload
    },
    clearAdListData:(state, action)=>{
        state={
            currentPage: 1,
            totalPages: 1,
            itemsPerPage: 10,
            totalItems: 0,
            searchQuery:"",
            sortOption: "",
            filter:{},
            categoryId:"",
            loading: false,
            error: null,
        }
    }
  },
});

export const { setAdListData, clearAdListData,setFilterData} = adListSlice.actions;
export default adListSlice.reducer;